export default {
  title: "Transport Records",
  placeholder_title: "Not included in any Linehaul",
  placeholder_subtitle: "In case it is, details will be shown here",
  not_especified: "Not defined",
  linehauls: "Linehauls",
  last_mile: "Last Mile",
  active_rute: "Active Route",
  team: "Team",
  project: "Project",
  not_asigned_yed: "--",
  not_included_in_any_project: "--",
};
