import { createRouter, createWebHistory } from "vue-router";
import type { RouteRecordRaw } from "vue-router";
import { useStorage } from "@vueuse/core";
import {
  DOCUMENT_TITLE,
  setDocumentTitle,
} from "@/common/helpers/documentTitle";
import { FormCreationTemplate } from "@/types/orders";
import { session } from "@letsgoi/areq";
// import posthog from "posthog-js";

import ArticlesCreationForm from "@/modules/clients/views/ArticlesCreationForm.vue";
const AuthLoginForm = () => import("@/modules/auth/LoginForm.vue");
const OrdersList = () =>
  import("@/modules/orders/views/list/OrderListView.vue");
const OrdersDetail = () =>
  import("@/modules/orders/views/detail/OrderDetailView.vue");
const OrdersCreationForm = () =>
  import("@/modules/orders/views/creation/CreationForm.vue");
const OrdersCreationFormLeroyMerlin = () =>
  import("@/modules/orders/views/creation/CreationFormLeroyMerlin.vue");
const OrdersCreationFormMediamarkt = () =>
  import("@/modules/orders/views/creation/CreationFormMediamarkt.vue");
const OrdersCreationFormStandard = () =>
  import("@/modules/orders/views/creation/CreationFormStandard.vue");
const OrdersCreationReverse = () =>
  import("@/modules/orders/views/creation/CreationFormReverse.vue");
const IncidencesList = () =>
  import("@/modules/incidences/views/list/IncidencesListView.vue");
const IncidenceDetail = () =>
  import("@/modules/incidences/views/detail/IncidenceDetailView.vue");
const IncidencesCreation = () =>
  import("@/modules/incidences/views/IncidenceCreationForm.vue");
const OperationsList = () =>
  import("@operations/views/list/OperationListView.vue");
const TeamList = () => import("@teams/views/list/TeamListView.vue");
const TeamDetail = () => import("@/modules/teams/views/TeamDetailView.vue");
const TeamCreation = () => import("@/modules/teams/views/CreationForm.vue");
const ProvidersList = () =>
  import("@/modules/providers/views/list/ProviderListView.vue");
const ProvidersCreationForm = () =>
  import("@/modules/providers/views/ProviderCreationForm.vue");
const ProviderDetail = () =>
  import("@/modules/providers/views/ProviderDetailView.vue");
const LinehaulsList = () =>
  import("@/modules/linehauls/views/list/LinehaulsListView.vue");
const LinehaulDetail = () =>
  import("@/modules/linehauls/views/detail/LinehaulDetailView.vue");
const LinehaulCreationForm = () =>
  import("@/modules/linehauls/views/LinehaulCreationForm.vue");

const PreViewer = () => import("@/common/components/PreViewer.vue");

const Routes = () => import("@/modules/routes/RouteViewContainer.vue");

const DedicatedServicesDetail = () =>
  import(
    "@/modules/dedicatedServices/views/detail/DedicatedServicesDetailView.vue"
  );

const DedicatedServicesCalendar = () =>
  import("@/modules/dedicatedServices/views/calendar/CalendarView.vue");

const DedicatedServicesCreationForm = () =>
  import(
    "@/modules/dedicatedServices/views/creation/DedicatedServicesCreationForm.vue"
  );

const AlertsList = () =>
  import("@/modules/alerts/views/list/AlertsListView.vue");

const AlertsDetail = () =>
  import("@/modules/alerts/views/detail/AlertDetailView.vue");

const NotFound = () => import("@/common/components/NotFound.vue");

const DedicatedServicesList = () =>
  import("@dedicatedServices/views/list/DedicatedServicesListView.vue");

const ClientsList = () =>
  import("@/modules/clients/views/list/ClientsListView.vue");

const ClientDetail = () =>
  import("@/modules/clients/views/detail/ClientDetailView.vue");

const ArticleDetail = () =>
  import("@/modules/clients/views/detail/ArticleDetailView.vue");

const ArticlesList = () =>
  import("@/modules/clients/views/list/ArticlesListView.vue");

const DealsList = () =>
  import("@/modules/clients/views/list/DealsListView.vue");

const DealCreationForm = () =>
  import("@/modules/clients/views/DealCreationForm.vue");

const DealDetail = () =>
  import("@/modules/clients/views/detail/DealDetailView.vue");

const ButtonsView = () => import("@/mocks/ButtonsView.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: { name: "orders" },
    meta: { requiresAuth: true },
  },
  {
    path: "/auth/login",
    name: "authLogin",
    component: AuthLoginForm,
  },
  {
    path: "/orders/list",
    name: "orders",
    component: OrdersList,
    meta: {
      requiresAuth: true,
      permissions: "list-orders || create-orders",
      menu: true,
      menuRef: "orders",
      title: DOCUMENT_TITLE.orders.list,
    },
  },
  {
    path: "/orders/:reference/detail",
    name: "ordersDetail",
    component: OrdersDetail,
    meta: {
      alertLink: "ordersIncidencesDetail",
      requiresAuth: true,
      permissions: "view-orders",
      menu: true,
      menuRef: "orders",
    },
  },
  {
    path: "/orders/create",
    name: "ordersCreation",
    redirect: () => {
      const profile: Record<string, any> = useStorage("profile", {});
      const template = profile?.value?.settings?.form_settings?.template;

      if (!template) {
        return "/orders/create-regular";
      } else if (template && template === FormCreationTemplate.LEROYMERLIN) {
        return "/orders/create-leroymerlin";
      } else if (template && template === FormCreationTemplate.MEDIAMARKT) {
        return "/orders/create-mediamarkt";
      } else if (template && template === FormCreationTemplate.STANDARD) {
        return "/orders/create-standard";
      } else {
        return "/notfound";
      }
    },
    meta: {
      requiresAuth: true,
      permissions: "create-orders",
      menu: true,
      menuRef: "orders",
      title: DOCUMENT_TITLE.orders.creation,
    },
  },
  {
    path: "/orders/create-regular",
    name: "ordersCreationRegular",
    component: OrdersCreationForm,
    meta: {
      requiresAuth: true,
      permissions: "create-orders",
      menu: true,
      menuRef: "orders",
      title: DOCUMENT_TITLE.orders.creation,
    },
  },
  {
    path: "/orders/create-leroymerlin",
    name: "ordersCreationLeroyMerlin",
    component: OrdersCreationFormLeroyMerlin,
    meta: {
      requiresAuth: true,
      permissions: "create-orders",
      menu: true,
      menuRef: "orders",
      title: DOCUMENT_TITLE.orders.creation,
    },
  },
  {
    path: "/orders/create-mediamarkt",
    name: "ordersCreationMediamarkt",
    component: OrdersCreationFormMediamarkt,
    meta: {
      requiresAuth: true,
      permissions: "create-orders",
      menu: true,
      menuRef: "orders",
      title: DOCUMENT_TITLE.orders.creation,
    },
  },
  {
    path: "/orders/create-standard",
    name: "OrdersCreationFormStandard",
    component: OrdersCreationFormStandard,
    meta: {
      requiresAuth: true,
      permissions: "create-orders",
      menu: true,
      menuRef: "orders",
      title: DOCUMENT_TITLE.orders.creation,
    },
  },
  {
    path: "/orders/:reference/reverse-create",
    name: "ordersCreationReverse",
    component: OrdersCreationReverse,
    meta: {
      requiresAuth: true,
      permissions: "create-orders",
      menu: true,
      menuRef: "orders",
      title: DOCUMENT_TITLE.orders.creation,
    },
  },
  {
    path: "/incidences/list",
    name: "incidences",
    component: IncidencesList,
    meta: {
      requiresAuth: true,
      permissions: "list-incidences",
      menu: true,
      menuRef: "incidences",
      title: DOCUMENT_TITLE.incidences.list,
    },
  },
  {
    path: "/incidences/create",
    name: "incidencesCreation",
    component: IncidencesCreation,
    meta: {
      requiresAuth: true,
      permissions: "create-incidences",
      menu: true,
      menuRef: "incidences",
      title: DOCUMENT_TITLE.incidences.creation,
    },
  },
  {
    path: "/incidences/:id/detail",
    name: "incidenceDetail",
    component: IncidenceDetail,
    meta: {
      requiresAuth: true,
      permissions: "view-incidences",
      menu: true,
      menuRef: "incidences",
    },
  },
  {
    path: "/operations/list",
    name: "operations",
    component: OperationsList,
    meta: {
      requiresAuth: true,
      permissions: "list-operations",
      menu: true,
      menuRef: "operations",
      title: DOCUMENT_TITLE.operations.list,
    },
  },
  {
    path: "/teams/list",
    name: "teams",
    component: TeamList,
    meta: {
      requiresAuth: true,
      permissions: "list-teams",
      menu: true,
      menuRef: "teams",
      title: DOCUMENT_TITLE.teams.list,
    },
  },
  {
    path: "/teams/:id/detail",
    name: "teamDetail",
    component: TeamDetail,
    meta: {
      requiresAuth: true,
      permissions: "view-teams",
      menu: true,
      menuRef: "teams",
    },
  },
  {
    path: "/teams/create",
    name: "teamCreation",
    component: TeamCreation,
    meta: {
      requiresAuth: true,
      permissions: "create-teams",
      menu: true,
      menuRef: "teams",
      title: DOCUMENT_TITLE.teams.creation,
    },
  },
  {
    path: "/providers/list",
    name: "providers",
    component: ProvidersList,
    meta: {
      requiresAuth: true,
      permissions: "list-providers || create-providers",
      menu: true,
      menuRef: "providers",
      title: DOCUMENT_TITLE.providers.list,
    },
  },
  {
    path: "/providers/create",
    name: "providerCreation",
    component: ProvidersCreationForm,
    meta: {
      requiresAuth: true,
      permissions: "create-providers",
      menu: true,
      menuRef: "providers",
      title: DOCUMENT_TITLE.providers.creation,
    },
  },
  {
    path: "/providers/:id/detail",
    name: "providerDetail",
    component: ProviderDetail,
    meta: {
      requiresAuth: true,
      permissions: "view-providers",
      menu: true,
      menuRef: "providers",
    },
  },
  {
    path: "/linehauls/list",
    name: "linehauls",
    component: LinehaulsList,
    meta: {
      requiresAuth: true,
      permissions: "list-linehauls || create-linehauls",
      menu: true,
      menuRef: "linehauls",
      title: DOCUMENT_TITLE.linehauls.list,
    },
  },
  {
    path: "/linehauls/:id/detail",
    name: "linehaulDetail",
    component: LinehaulDetail,
    meta: {
      requiresAuth: true,
      permissions: "view-linehauls",
      menu: true,
      menuRef: "linehauls",
    },
  },
  {
    path: "/linehauls/create",
    name: "linehaulCreation",
    component: LinehaulCreationForm,
    meta: {
      requiresAuth: true,
      permissions: "create-linehauls",
      menu: true,
      menuRef: "linehauls",
      title: DOCUMENT_TITLE.linehauls.creation,
    },
  },

  {
    path: "/pre-viewer/:id",
    name: "preViewer",
    component: PreViewer,
  },
  {
    path: "/dedicated-services/list",
    name: "dedicatedServices",
    component: DedicatedServicesList,
    meta: {
      requiresAuth: true,
      permissions: "list-dedicated_services || create-dedicated_services",
      menu: true,
      menuRef: "dedicated-services",
      title: DOCUMENT_TITLE.dedicated.list,
    },
  },
  {
    path: "/dedicated-services/:reference/detail",
    name: "dedicatedServicesDetail",
    component: DedicatedServicesDetail,
    meta: {
      requiresAuth: true,
      permissions: "view-dedicated_services",
      menu: true,
      menuRef: "dedicated-services",
    },
  },
  {
    path: "/dedicated-services/calendar",
    name: "DedicatedServicesCalendar",
    component: DedicatedServicesCalendar,
    meta: {
      requiresAuth: true,
      permissions: "list-dedicated_services",
      title: DOCUMENT_TITLE.dedicated.calendar,
      menu: true,
      menuRef: "dedicated-services",
    },
  },
  {
    path: "/dedicated-services/:categoryId/creation",
    name: "DedicatedServicesCreationForm",
    component: DedicatedServicesCreationForm,
    meta: {
      requiresAuth: true,
      permissions: "create-dedicated_services",
      menu: true,
      menuRef: "dedicated-services",
      title: DOCUMENT_TITLE.dedicated.creation,
    },
  },
  {
    path: "/alerts/list",
    name: "alerts",
    component: AlertsList,
    meta: {
      requiresAuth: true,
      menu: true,
      menuRef: "alerts",
      scope: "cortex",
      title: DOCUMENT_TITLE.alerts.list,
    },
  },
  {
    path: "/alerts/:reference/detail",
    name: "alertsDetail",
    component: AlertsDetail,
    meta: {
      requiresAuth: true,
      menu: true,
      scope: "cortex",
      menuRef: "alerts",
    },
  },
  {
    path: "/routes/:pathMatch(.*)",
    name: "routes",
    component: Routes,
    meta: {
      menu: true,
      menuRef: "routes",
    },
  },
  {
    path: "/clients/list",
    name: "clients",
    component: ClientsList,
    meta: {
      requiresAuth: true,
      permissions: "list-clients",
      menu: true,
      menuRef: "clients",
      title: DOCUMENT_TITLE.clients.list,
    },
  },
  {
    path: "/clients/:id/detail",
    name: "clientDetail",
    component: ClientDetail,
    meta: {
      requiresAuth: true,
      permissions: "view-clients",
      menu: true,
      menuRef: "clients",
    },
  },
  {
    path: "/clients/:id/articles/list",
    name: "articles",
    component: ArticlesList,
    meta: {
      requiresAuth: true,
      permissions: "list-articles",
      menu: true,
      menuRef: "articles",
      title: DOCUMENT_TITLE.articles.list,
    },
  },
  {
    path: "/clients/:id/articles/:articleId/detail",
    name: "articleDetail",
    component: ArticleDetail,
    meta: {
      requiresAuth: true,
      permissions: "view-articles",
      menu: true,
      menuRef: "articles",
    },
  },
  {
    path: "/clients/:id/articles/creation",
    name: "articleCreation",
    component: ArticlesCreationForm,
    meta: {
      requiresAuth: true,
      menu: true,
      menuRef: "articles",
      title: DOCUMENT_TITLE.articles.creation,
    },
  },
  {
    path: "/clients/deals/list",
    name: "deals",
    component: DealsList,
    meta: {
      requiresAuth: true,
      menu: true,
      menuRef: "deals",
      title: DOCUMENT_TITLE.deals.list,
    },
  },
  {
    path: "/clients/deals/:id/detail",
    name: "dealDetail",
    component: DealDetail,
    meta: {
      requiresAuth: true,
      permissions: "view-deals",
      menu: true,
      menuRef: "deals",
    },
  },
  {
    path: "/clients/deals/:id/create",
    name: "dealCreation",
    component: DealCreationForm,
    meta: {
      requiresAuth: true,
      menu: true,
      menuRef: "deals",
      title: DOCUMENT_TITLE.deals.creation,
    },
  },
  {
    path: "/buttons",
    name: "buttons",
    component: ButtonsView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const requiresAuth = to.matched.some((record) => record.meta?.requiresAuth);
  if (!requiresAuth) {
    return true;
  }

  const params = new URL(location.href).searchParams;
  const refreshToken = params.get("refresh_token");
  if (refreshToken) {
    return { name: "authLogin", query: { refresh_token: refreshToken } };
  }

  const token = session.getToken();
  if (!token?.access_token && to.name !== "auth") {
    return { name: "authLogin" };
  }

  /*
  const store = globalThis.localStorage.getItem("profile");
  if (store) {
    const profile = (JSON.parse(store) ?? {}) as Auth.Profile;
    if (profile?.email) {
      posthog.identify(profile?.id, {
        email: profile?.email,
        name: profile?.name,
      });
    }
    posthog.capture("app_version", {
      version: VITE_APP_RELEASE,
    });
  }
   */

  setDocumentTitle(to?.meta?.title ?? DOCUMENT_TITLE.default);
  if (!to?.meta?.permissions && !to.meta?.scope) {
    return true;
  }

  if (to?.meta?.permissions) {
    const isAuth = globalThis.passport(to?.meta?.permissions);
    if (!isAuth) return { name: "authLogin" };
  }

  if (to?.meta?.scope) {
    const isAuth = globalThis.passportScope(to?.meta?.scope);
    if (!isAuth) return { name: "authLogin" };
  }

  return true;
});

router.onError((error, to) => {
  const isDeployError =
    error.message.includes("Failed to fetch dynamically imported module") ||
    error.message.includes("Importing a module script failed");

  if (isDeployError) {
    window.location.href = to.fullPath;
  }
});

export default router;
