<template>
  <div class="flex flex-grow flex-col overflow-y-auto">
    <div id="headway" class="flex flex-shrink-0 items-center px-4 mb-5">
      <BrainLogoIcon
        v-if="!authStore.gettProfile?.settings?.sidebar_setting?.logo_url"
      />

      <div
        v-else
        class="h-full max-h-[100px] min-h-[60px] w-[130px] bg-contain bg-top bg-no-repeat"
        :style="{ backgroundImage: `url(${sidebarLogo})` }"
      />
    </div>

    <div
      v-if="passport('search')"
      class="mx-2 grid cursor-pointer grid-cols-[min-content_1fr_min-content] items-center gap-x-2 border border-l-0 border-r-0 border-b-sky-300 border-t-sky-300 p-2 pr-0 text-sm font-medium text-white"
      @click="emit('open:global:search')"
    >
      <MagnifyingGlassIcon class="size-5 text-white" />
      Search
      <kbd
        class="inline-flex h-full items-center justify-center rounded-md border border-sky-300 px-1.5 py-0.5 text-sm font-normal leading-5 text-sky-300"
      >
        <span class="mr-1 text-sm leading-3">{{ shortcutKeySearch }}</span> K
      </kbd>
    </div>

    <div class="mt-2 flex flex-grow flex-col">
      <nav class="flex-1 space-y-1 px-2" aria-label="Sidebar">
        <template v-for="item in menu" :key="item.name">
          <!-- OPCIONES QUE NO TIENEN HIJOS -->
          <ParentVoidDisclosureBtn
            v-if="
              item.children?.length === 0 &&
              (item.permissions ? passport(item.permissions) : true) &&
              (item?.scope ? passportScope(item.scope) : true)
            "
            :item="item"
            @navigate:to="handleClick"
            :class="{
              hidden: item?.reference === 'shortcuts',
            }"
          />

          <!-- PADRES QUE TIENEN HIJOS -->
          <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }">
            <ParentDisclosureBtn
              :item="item"
              :open="open"
              v-if="
                (item?.permissions ? passport(item?.permissions) : true) &&
                (item?.scope ? passportScope(item.scope) : true)
              "
            />

            <!-- HIJOS -->
            <DisclosurePanel
              v-if="item?.reference !== 'shortcuts'"
              class="space-y-1"
            >
              <ChildDisclosureButtons
                :item="item"
                @navigate:to="handleChildrenClick"
              />
            </DisclosurePanel>

            <!-- Draggable Hijos -->
            <DisclosurePanel v-else class="space-y-1">
              <div v-for="(value, key) in shortCuts" :key="key">
                <h2 class="ml-10 text-sm font-semibold uppercase text-white">
                  {{ key }}
                </h2>
                <ChildDisclosureGraggableButtons
                  :section-menu="item"
                  :section-menu-childrens="value"
                  :group="key"
                  @open:modal:confirm="handlerConfirmDeleteShortcut"
                  @navigate:to="handleChildrenClick"
                  @update:menu-childrens="handleUpdateShortCuts"
                />
              </div>
            </DisclosurePanel>
          </Disclosure>
        </template>
      </nav>
    </div>
    <!-- MODAL delete shortcut-->
    <ModalCenter
      :show="modalConfirmDeleteShortcut"
      @dialog:close="(state) => (modalConfirmDeleteShortcut = state)"
    >
      <div class="bg-opacity-15 px-4 pb-4 pt-5 text-left sm:w-full sm:max-w-lg">
        <div class="flex flex-row gap-2">
          <ExclamationTriangleIcon
            class="h-11 w-11 text-red-300"
            aria-hidden="true"
          />
          <div>
            <p class="mb-2 text-lg text-gray-900">
              {{ $t("common.components.sideAppMenu.label_delete_shortcut") }}
            </p>
            <p class="text-sm font-light text-gray-500">
              {{
                $t("common.components.sideAppMenu.description_delete_shortcut")
              }}
            </p>
          </div>
        </div>
        <div
          class="my-5 justify-items-end sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-4 sm:gap-3"
        >
          <button
            type="button"
            class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white hover:bg-red-600/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-4"
            @click="handleDeleteShortcut(shortcutDeleted)"
          >
            {{ $t("common.components.sideAppMenu.btn_delete") }}
          </button>
          <button
            type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-3 sm:mt-0"
            @click="modalConfirmDeleteShortcut = false"
            ref="cancelButtonRef"
          >
            {{ $t("common.components.sideAppMenu.btn_cancel") }}
          </button>
        </div>
      </div>
    </ModalCenter>

    <div class="flex items-center justify-center divide-x px-2 py-4 text-white">
      <BrainLogoCropedIcon class="mr-2 h-9 w-9" />
      <p
        class="mr-2 pl-2 text-sm font-medium leading-5"
        :title="authStore.gettProfile?.email"
      >
        {{ authStore.gettProfile?.name }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, onMounted } from "vue";
import { Disclosure, DisclosurePanel } from "@headlessui/vue";
import ChildDisclosureGraggableButtons from "./ChildDisclosureGraggableButtons.vue";
import ChildDisclosureButtons from "./ChildDisclosureButtons.vue";
import ParentDisclosureBtn from "./ParentDisclosureBtn.vue";
import ParentVoidDisclosureBtn from "./ParentVoidDisclosureBtn.vue";
import { computed } from "vue";
import groupBy from "lodash-es/groupBy";
import sortBy from "lodash-es/sortBy";
import find from "lodash-es/find";
import reject from "lodash-es/reject";
import filter from "lodash-es/filter";
import BrainLogoIcon from "@/assets/img/BrainLogoIcon.vue";
import BrainLogoCropedIcon from "@/assets/img/BrainLogoCropedIcon.vue";
import { useRouter, useRoute } from "vue-router";
import { FilterFactory } from "@letsgoi/qp";
import { useAuthStore } from "@/modules/auth/store";
import { reqDeleteShortcut } from "@/common/helpers/api";
import useErrorHandler from "@/common/composables/useErrorHandler";
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";
import ModalCenter from "@/common/components/ModalCenter.vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import {
  passportKey,
  passportRoleKey,
  passportScopeKey,
} from "@/common/plugins/passport";
import { useOperatingSystem } from "@/common/composables/useUserAgent";

const operatingSystem = useOperatingSystem();
const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();
const errorHandler = useErrorHandler();
const menu = inject("menu", []);
const modalConfirmDeleteShortcut = ref(false);
const shortcutDeleted = ref({});
const passport = inject(passportKey);
const passportRole = inject(passportRoleKey);
const passportScope = inject(passportScopeKey);

const emit = defineEmits(["open:global:search"]);

onMounted(() => {
  menu.value = menu.value?.map((option) => ({
    ...option,
    current: option?.reference === route?.meta?.menuRef,
  }));

  if (passportRole("goi")) {
    globalThis?.Headway?.init({
      selector: "#headway",
      account: "xGGOAx",
      callbacks: {
        onWidgetReady: function (widget) {
          const count = widget?.getUnseenCount();
          if (count > 0) widget.toggle();
        },
        onShowWidget: function () {
          const hw = document.getElementById("HW_frame_cont");
          if (hw) {
            hw.style = "top:45px;left:15px";
          }
        },
      },
    });
  }
});
const setCurrent = (id) => (option) => ({
  ...option,
  current: id === option?.id,
});
const setCurrentChildren = (menuId, childrenId) => (option) => ({
  ...option,
  current: menuId === option?.id,
  children: option?.children?.map(setCurrent(childrenId)),
});
const handleChildrenClick = ({ item, subItem }) => {
  menu.value = menu.value.map(setCurrentChildren(item?.id, subItem?.id));
  if (subItem?.pathname) navigateTo(subItem);
};

const sidebarLogo = computed(() => {
  const { settings } = authStore.gettProfile;
  return settings?.sidebar_setting?.logo_url;
});

const shortcutKeySearch = computed(() => {
  return operatingSystem.isMacOS.value ? "⌘" : "Ctrl";
});

async function navigateTo({ pathname, search }) {
  const filterFactory = new FilterFactory();
  const query = filterFactory.urlToVueRouterQueryParams(search);
  query.uuid = crypto.randomUUID();
  await router.push({
    path: pathname?.pathname,
    query,
  });
}

const handleClick = async (item) => {
  menu.value = menu.value.map(setCurrent(item.id));
  if (item?.pathname) await navigateTo(item);
};
const groupByUrl = (subItems) => {
  try {
    const groupedItems = groupBy(subItems, "type");
    return Object.keys(groupedItems).reduce((acc, key) => {
      const group = sortBy(groupedItems[key], "sort");
      return {
        ...acc,
        [key]: group,
      };
    }, {});
  } catch (e) {
    console.error(e);
  }
};
const shortCuts = computed(() => {
  const item = find(menu.value, { reference: "shortcuts" });
  return groupByUrl(item?.children);
});
const handleDeleteShortcut = async (item) => {
  let shortCuts = find(menu.value, { reference: "shortcuts" });

  const shortcutByType = filter(shortCuts?.children, { type: item.type });
  const shortcutsWithoutItemSelected = reject(shortcutByType, { id: item.id });

  const shortcutWithoutType = reject(shortCuts?.children, { type: item.type });

  const sortedShortcuts = shortcutsWithoutItemSelected.map(
    (shortcut, index) => ({
      ...shortcut,
      sort: index,
    })
  );

  shortCuts.children = [...shortcutWithoutType, ...sortedShortcuts];

  try {
    await reqDeleteShortcut(item.id);
  } catch (exception) {
    await errorHandler.handleApiExceptions(exception);
  } finally {
    modalConfirmDeleteShortcut.value = false;
  }
};
const handleUpdateShortCuts = ({ items }) => {
  const reIndexItems = items.map((item, index) => ({
    ...item,
    sort: index,
  }));

  let shortCuts = find(menu.value, { reference: "shortcuts" });
  shortCuts.children = shortCuts.children.map((item) => {
    const reIndexItem = find(reIndexItems, { id: item?.id });
    return reIndexItem ?? item;
  });
};

function handlerConfirmDeleteShortcut(element) {
  modalConfirmDeleteShortcut.value = true;
  shortcutDeleted.value = element;
}
</script>
