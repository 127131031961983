import areq from "@/common/helpers/aReq";
import AreqTools from "@letsgoi/areq";
import { type SearchItem } from "@/common/components/SidebarAppMenu/index";


import {
  STATUS,
  ZONES,
  SERVICES,
  COUNTRIES,
  SKILLS,
  PROVINCES,
  LINEHAUL_STATUSES,
  LINEHAUL_TYPES,
  COMMENTS,
  SEARCH,
} from "./paths";

const {
  GET,
  POST,
  setBody,
  jsonData,
  setPassport,
  setLetgoiHeaders,
  setApiHost,
} = AreqTools;

//indexStatus
export function reqGetListStatus() {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-order_statuses"), []))
    .then(GET(STATUS))
    .then(jsonData<Orders.Status[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetServices() {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-services"), []))
    .then(GET(SERVICES))
    .then(jsonData<Orders.Service[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetCountriesList() {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-countries"), []))
    .then(GET(COUNTRIES))
    .then(jsonData<Country[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetSkillsList() {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-skills"), []))
    .then(GET(SKILLS))
    .then(jsonData<Skill[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetProvinces() {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-provinces"), []))
    .then(GET(PROVINCES))
    .then(jsonData<Province[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetLinehaulStatuses() {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-linehaul_statuses"), []))
    .then(GET(LINEHAUL_STATUSES))
    .then(jsonData<Linehauls.Status[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetLinehaulTypes() {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-linehaul_types"), []))
    .then(GET(LINEHAUL_TYPES))
    .then(jsonData<Linehauls.Type[]>())
    .catch((error) => Promise.reject(error));
}

export function reqPostReplyToComment(
  commentId: string,
  comment: string,
  tags?: string[]
) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody({ comment, tags }))
    .then(setPassport(globalThis.passport("create-orders-comments"), {}))
    .then(POST(`${COMMENTS}/${commentId}/comments`))
    .then(jsonData())
    .catch((error) => Promise.reject(error));
}

export function reqGetZonesList(queryParams) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-zones"), []))
    .then(GET(`${ZONES}${queryParams}`))
    .then(jsonData<Api<IdName[]> | IdName[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetAlertZonesList(queryParams) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setApiHost(import.meta.env.VITE_APP_CORTEX_API_HOST))
    .then(GET(`${ZONES}${queryParams}`))
    .then(jsonData<Api<IdName[]> | IdName[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetSearch(queryParams) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("search"), []))
    .then(GET(`${SEARCH}${queryParams}`))
    .then(jsonData<SearchItem[]>())
    .catch((error) => Promise.reject(error));
}
