import SidebarAppMenu from "./MainComponent.vue";

export enum SearchItemType {
  ORDER = "order",
  INCIDENCE = "incidence",
  LINEHAUL = "linehaul",
  DEDICATED_SERVICE = "dedicated-service",
}

export interface SearchItem {
  type: SearchItemType;
  route_key: string;
  value: string;
  display_values: {
    reference: string;
    value: string;
    client: string;
    operation: string;
  };
}

export default SidebarAppMenu;
