export default {
  title: "Provider",
  provider: "Provider",
  team_assigned: "Team Assigned",
  plate: "Plate",
  team_contact_number: "Team Contact Number",
  edit_provider: "Edit Provider",
  provider_assigned: "Provider Assigned",
  not_especified: "--",
};
