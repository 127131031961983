export default {
  title: "Supplements",
  button_add: "Add Supplement",
  title_placeholder_empty: "No supplements added yet",
  subtitle_placeholder_empty: "Add supplements if apply",
  placeholder_input: "Choose from the list",
  add_to_service: "Add to the service",
  choose_supplement: "Choose supplement",
  placeholder: "Select from the list",
  value: "Value",
  button_edit: "Edit",
};
