export default {
  "module-name": "Clients",
  clients: "Clients",
  business: "Business Name",
  logistic_name: "Logistic Name",
  created_at: "Creation date",
  client_info: "Client Info",
  edit_contact: "Edit Contact",
  unspecified: "--",
  no_articles: "No Articles Added",
  operations: "Operations",
  no_operations: "No Operations Specified",
  view_all: "View all",
  reference: "Reference",
  name: "Name",
  weight: "Weight",
  volume: "Volume",
  dimensions: "Dimensions",
  articles: "Articles",
  article: "Article",
  article_reference: "Article Reference",
  article_reference_lowercase: "Article reference",
  article_measures: "Article Measures",
  article_measures_lowercase: "Article measures",
  client_catalogue: "Client Catalogue",
  edit_article: "Edit Article Info",
  metadata: "Metadata",
  "no-metadata": "There is no Metadata to show",
  measures: "Measures",
  edit_measures: "Edit Measures",
  width: "Width",
  length: "Length",
  height: "Height",
  deleting: "Deleting Article",
  delete_article: "Delete Article",
  delete: "Delete",
  tags: "Tags",
  action_undone:
    "This action can't be undone. Are you sure you want to proceed?",
  "create-article": "New Article",
  deals: "Deals",
  deal_proposal_name: "Proposal’s Name",
  deal_agreed_units: "Agreed Units",
  minimum_orders: "Minimum Orders",
  additional_piece_from: "Additional piece from",
  home_delivery_pricing: "Home Delivery Pricing",
  pickup_pricing: "Pick up Pricing",
  standard: "Standard",
  heavy: "Heavy",
  bulky_parcels: "Bulky Parcels",
  super_heavy: "Super Heavy",
  other_costs: "Other costs",
  assembly_pricing: "Assembly/Installation Pricing",
  assurance_pricing: "Assurance Pricing",
  pick_up_provinces: "Pick up Province",
  extra_info: "Extra info",
  add_additional_info: "Add additional info",
  provinces: "Province",
  raee_costs: "RAEE Costs",
  raee_pricing: "RAEE Pricing",
  raee_payment: "RAEE Payment",
  no: "No",
  yes: "Yes",
  placeholder_name: "Insert name",
  placeholder_minimun_orders: "Insert minimun orders",
  placeholder_additional_piece_from: "Insert additional piece from",
  placeholder_price: "Insert price",
  placeholder_provinces: "Select province",
  placeholder_extra: "Insert extra info",
  added_on: "Added on",
  create_proposal: "Add Proposal",
  creating_proposal: "Creating Proposal",
  proposals: "Proposals",
  location: "Location",
};
