export default {
  title: "Orders",
  ref: "Ref. ID",
  client: "Client",
  operation: "Operation",
  status: "Status",
  add_order: "Add order",
  view_all: "View all",
  pkgs: "Pkg. | Pkgs.",
  label_search: " Search orders to add",
  placeholder_search: "by Ref ID or Client Order ID",
  title_placeholder_empty: "No {content} added",
  subtitle_placeholder_empty: "Get started by adding content",
};
