export default {
  title: "Proposals",
  name: "Name",
  create_on: "Created on",
  name_proposal: "Name Proposal",
  proposal_details: "Proposal Details",
  duplicate: "Duplicate",
  download: "Download",
  publish: "Publish",
  starting_date: "To be applied from",
  publishing: "Publishing",
  title_placeholder_empty: "No proposals recorded",
  subtitle_placeholder_empty: "Get started by adding content",
  standard_home_delivery_pricing: "Standard Home Delivery Pricing",
  heavy_home_delivery_pricing: "Heavy Home Delivery Pricing",
  bulky_home_delivery_pricing: "Bulky Parcel Home Delivery Pricing",
  superheavy_home_delivery_pricing: "Super Heavy Home Delivery Pricing",
  raee_payment: "RAEE Payment Yes or No",
  raee_pricing: "RAEE Pricing",
  additional_piece_from: "Additional Piece from",
  assembly_pricing: " Assembly/Installation Pricing",
  assurance_pricing: "Assurance Pricing",
  minimum_orders: "Minimum Orders",
  pick_up_province: "Pick up Province",
  standard_pickup_pricing: "Standard Pick up Pricing",
  heavy_pickup_pricing: "Heavy Pick up Pricing",
  bulky_pickup_pricing: "Bulky Parcel Pick up Pricing",
  superheavy_pickup_pricing: "Super Heavy Pick up Pricing",
  extra_info: "Extra info",
  apply_proposal_only: "Apply proposal only to selected operations",
};
