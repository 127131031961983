export const USERS = "/users";
export const OAUTH = "/oauth/token";
export const ORDERS = "/orders";
export const ADDRESS = "/address";
export const INCIDENCES = "/incidences";
export const OPERATIONS = "/operations";
export const CLIENTS = "/clients";
export const SERVICES = "/services";
export const STATUS = "/statuses";
export const TEAMS = "/teams";
export const INCIDENCE_TYPES = "/incidence-types";
export const INCIDENCE_PRIORITIES = "/incidence-priorities";
export const INCIDENCE_STATUSES = "/incidence-statuses";
export const INCIDENCE_LIABLES = "/incidence-liables";
export const INCIDENCE_CLOSE_REASONS = "/incidence-close-reasons";
export const ROUTES = "/routes";
export const PROJECTS = "/projects";
export const TAGS = "/tags";
export const COUNTRIES = "/countries";
export const BULK_STATUSES = "/statuses/bulk";
export const BULK_ORDERS = "/bulk";
export const BULK_EVENT_LOGS = "/event-logs";
export const COMMENTS = "/comments";
export const BULK_INCIDENCES = "/incidences/bulk";
export const SKILLS = "/skills";
export const PROVIDERS = "/providers";
export const PROVIDER_TYPES = "/provider-types";
export const LINEHAULS = "/linehauls";
export const USERS_PROFILE = "/profile";
export const PROVINCES = "/provinces";
export const LINEHAUL_STATUSES = "/linehaul-statuses";
export const LINEHAUL_TYPES = "/linehaul-types";
export const ARTICLES = "/articles";
export const DEDICATED_SERVICES = "/dedicated-services";
export const DEDICATED_SERVICE_CATEGORIES = "/dedicated-service-categories";
export const DEDICATED_SERVICE_TYPES = "/dedicated-service-types";
export const DEDICATED_SERVICE_SUBTYPES = "/dedicated-service-subtypes";
export const DEDICATED_SERVICE_STATUSES = "/dedicated-service-statuses";
export const ZONES = "/zones";
export const SHORTCUTS = "/shortcuts";
export const ORDER_FLOWS = "/order-flows";
export const INVOICE = "/invoice-line-items";
export const INVOICE_TYPES = "/invoice-line-item-types";
export const ALERTS = "/alerts";
export const DEALS = "/deals";
export const PALLETS = "/pallets";
export const SEARCH = "/search";
