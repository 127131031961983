export { default as common } from "./common";
import cardServiceInfo from "./cardServiceInfo";
import cardMiniDedicatedServiceReference from "./cardMiniDedicatedServiceReference";
import cardMiniClientOperation from "./cardMiniClientOperation";
import cardMiniDedicatedServiceStatus from "./cardMiniDedicatedServiceStatus";
import cardServiceLogs from "./cardServiceLogs";
import cardServiceAddresses from "./cardServiceAddresses";
import cardServiceProvider from "./cardServiceProvider";
import cardServiceContact from "./cardServiceContact";
import cardServiceSupplements from "./cardServiceSupplements";

export const card = {
  cardServiceInfo,
  cardMiniDedicatedServiceReference,
  cardMiniClientOperation,
  cardMiniDedicatedServiceStatus,
  cardServiceLogs,
  cardServiceAddresses,
  cardServiceProvider,
  cardServiceContact,
  cardServiceSupplements,
};
