<template>
  <div class="w-full bg-gray-100 px-4 sm:px-6 lg:px-8">
    <!-- ACTIONS BUTTONS HEADER -->
    <div
      class="sticky top-0 z-10 flex items-center justify-end gap-x-6 border-b border-b-gray-200 bg-gray-100 px-4 py-3"
    >
      <button
        type="button"
        :disabled="saving"
        class="btn-primary-md"
        @click="handleSave"
      >
        <loader-circle
          v-if="saving"
          class="h-6 w-6 animate-spin fill-blue-600 text-gray-200"
        />
        <span v-else> {{ t("common.button.save") }} </span>
      </button>
      <button
        type="button"
        :disabled="saving"
        class="btn-secondary-md"
        @click="router.go(-1)"
      >
        {{ t("common.button.cancel") }}
      </button>
    </div>

    <!-- FORM --->
    <div class="mx-auto mt-4 max-w-2xl">
      <form>
        <div class="space-y-4">
          <!-- ARTICLE REFERENCE -->
          <div class="border-b border-gray-900/10 pb-4">
            <div class="flex gap-x-4">
              <div
                class="box-border size-9 justify-self-start rounded-xl border border-slate-300 bg-white p-2 shadow md:justify-self-end"
              >
                <TagIcon class="size-5 text-slate-500" />
              </div>
              <h3
                class="col-span-full flex h-9 items-center text-lg font-medium text-gray-900/80"
              >
                {{ t("clients.article_reference_lowercase") }}
              </h3>
            </div>

            <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-2.5 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <InputBox
                  id="externalId"
                  name="externalId"
                  class="w-full"
                  v-model="model.external_id"
                  :placeholder="t('orders.card.articles.placeholder_ref')"
                  :error="{
                    exist: v$?.external_id?.$error,
                    message: v$?.external_id?.$errors[0]?.$message,
                  }"
                >
                  {{ t("clients.reference") }}
                </InputBox>
              </div>

              <div class="col-span-full">
                <label
                  for="about"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >{{ t("clients.name") }}</label
                >
                <div class="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    rows="3"
                    v-model="model.name"
                    :placeholder="t('orders.card.articles.placeholder_name')"
                    :class="{
                      'pr-10 text-red-900 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-red-500':
                        v$?.name?.$error,
                    }"
                    class="block w-full resize-none rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <p v-if="v$?.name?.$error" class="mt-2 text-sm text-red-600">
                    {{ v$?.name?.$errors[0]?.$message }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- ARTICLE MEASURES -->
          <div class="border-b border-gray-900/10 pb-4">
            <div class="flex gap-x-4">
              <div
                class="box-border size-9 justify-self-start rounded-xl border border-slate-300 bg-white p-2 shadow md:justify-self-end"
              >
                <SwatchIcon class="size-5 text-slate-500" />
              </div>
              <h3
                class="col-span-full flex h-9 items-center text-lg font-medium text-gray-900/80"
              >
                {{ t("clients.article_measures_lowercase") }}
              </h3>
            </div>

            <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-2.5 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <InputBox
                  id="weight"
                  name="weight"
                  type="number"
                  :placeholder="t('orders.card.articles.placeholder_weight')"
                  class="w-full"
                  v-model="model.weight"
                  :error="{
                    exist: v$?.weight?.$error,
                    message: v$?.weight?.$errors[0]?.$message,
                  }"
                >
                  {{ t("clients.weight") }}
                  <template #legend> kg </template>
                </InputBox>
              </div>

              <div class="sm:col-span-3">
                <InputBox
                  id="volume"
                  name="volume"
                  type="number"
                  :placeholder="t('orders.card.articles.placeholder_volume')"
                  class="w-full"
                  v-model="model.volume"
                  :error="{
                    exist: v$?.volume?.$error,
                    message: v$?.volume?.$errors[0]?.$message,
                  }"
                >
                  {{ t("clients.volume") }}
                  <template #legend> m3 </template>
                </InputBox>
              </div>

              <div class="sm:col-span-3">
                <InputBox
                  id="width"
                  name="width"
                  type="number"
                  :placeholder="t('orders.card.articles.placeholder_width')"
                  class="w-full"
                  v-model="model.width"
                  :error="{
                    exist: v$?.width?.$error,
                    message: v$?.width?.$errors[0]?.$message,
                  }"
                >
                  {{ t("clients.width") }}
                  <template #legend> cm </template>
                </InputBox>
              </div>

              <div class="sm:col-span-3">
                <InputBox
                  id="height"
                  name="height"
                  type="number"
                  :placeholder="t('orders.card.articles.placeholder_height')"
                  class="w-full"
                  v-model="model.height"
                  :error="{
                    exist: v$?.height?.$error,
                    message: v$?.height?.$errors[0]?.$message,
                  }"
                >
                  {{ t("clients.height") }}
                  <template #legend> cm </template>
                </InputBox>
              </div>

              <div class="sm:col-span-3">
                <InputBox
                  id="length"
                  name="length"
                  type="number"
                  :placeholder="t('orders.card.articles.placeholder_length')"
                  class="w-full"
                  v-model="model.length"
                  :error="{
                    exist: v$?.length?.$error,
                    message: v$?.length?.$errors[0]?.$message,
                  }"
                >
                  {{ t("clients.length") }}
                  <template #legend> cm </template>
                </InputBox>
              </div>
            </div>
          </div>

          <!-- TAGS -->
          <div class="pb-4">
            <div class="flex gap-x-4">
              <div
                class="box-border size-9 justify-self-start rounded-xl border border-slate-300 bg-white p-2 shadow md:justify-self-end"
              >
                <LightBulbIcon class="size-5 text-slate-500" />
              </div>
              <h3
                class="col-span-full flex h-9 items-center text-lg font-medium text-gray-900/80"
              >
                {{ t("clients.tags") }}
              </h3>
            </div>

            <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-2.5 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <ComboBox
                  :model-value="model.tags"
                  :list-values="list"
                  :placeholder="
                    t('common.components.filterMachine.single.search')
                  "
                  :on-search="handleSearch"
                  :on-custom-value-input="handleCustomValueInput"
                  :loading="loadingTags"
                  multiple
                  class="w-full"
                  @update:model-value="handleTagSelected"
                >
                </ComboBox>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { TagIcon, SwatchIcon, LightBulbIcon } from "@heroicons/vue/24/outline";
import InputBox from "@/common/components/InputBox.vue";
import ComboBox from "@/common/components/ComboBox.vue";
import { useI18n } from "vue-i18n";
import { computed, reactive, ref } from "vue";
import { EMPTY_STRING } from "@/common/helpers/constants";
import debounce from "lodash-es/debounce";
import {
  reqGetSuggestedTags,
  reqPostCreateArticle,
} from "@/common/helpers/api";
import useErrorHandler from "@/common/composables/useErrorHandler";
import {
  decimal,
  maxLength,
  maxValue,
  minValue,
  required,
  requiredIf,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useRoute, useRouter } from "vue-router";
import LoaderCircle from "@/common/components/LoaderCircle.vue";
import isNumber from "lodash-es/isNumber";

const { t } = useI18n();

const router = useRouter();
const route = useRoute();

const model = reactive<{
  external_id: string;
  name: string;
  weight: number | undefined;
  volume: number | undefined;
  width: number | undefined;
  length: number | undefined;
  height: number | undefined;
  tags: Tag[];
}>({
  external_id: EMPTY_STRING,
  name: EMPTY_STRING,
  weight: undefined,
  volume: undefined,
  width: undefined,
  height: undefined,
  length: undefined,
  tags: [],
});

const list = ref<Tag[]>([]);
const loadingTags = ref(false);
const saving = ref(false);

const errorHandler = useErrorHandler();

const rules = computed(() => {
  return {
    external_id: {
      required,
      maxLength: maxLength(191),
      $lazy: true,
    },
    name: {
      required,
      maxLength: maxLength(191),
      $lazy: true,
    },
    weight: {
      decimal,
      minValue: minValue(0),
      maxValue: maxValue(10000000),
      $lazy: true,
    },
    volume: {
      decimal,
      minValue: minValue(0),
      maxValue: maxValue(10000000),
      $lazy: true,
    },
    width: {
      decimal,
      minValue: minValue(0),
      required: requiredIf(
        () => isNumber(model?.length) || isNumber(model?.height)
      ),
      $lazy: true,
    },
    height: {
      decimal,
      minValue: minValue(0),
      required: requiredIf(
        () => isNumber(model?.width) || isNumber(model?.length)
      ),
      $lazy: true,
    },
    length: {
      decimal,
      minValue: minValue(0),
      required: requiredIf(
        () => isNumber(model?.width) || isNumber(model?.height)
      ),
      $lazy: true,
    },
  };
});

const v$ = useVuelidate(rules, model);

const handleSearch = debounce(async (tag) => {
  try {
    loadingTags.value = true;
    if (tag.length < 3) {
      list.value = [];
      return;
    }
    if (tag.length < 3) {
      return;
    }
    list.value = await reqGetSuggestedTags(`?filter[name]=${tag}`);
  } catch (error) {
    await errorHandler.handleApiExceptions(error);
  } finally {
    loadingTags.value = false;
  }
}, 100);

function handleTagSelected(tags: Tag[]) {
  model.tags = tags;
}

function handleCustomValueInput(term: string): Tag[] {
  return [{ id: crypto.randomUUID(), name: term }];
}

async function handleSave() {
  try {
    v$.value.$touch();
    if (v$.value.$error) {
      return errorHandler.alertVuelidateErrors(v$.value);
    }

    saving.value = true;

    await reqPostCreateArticle({
      client_id: route?.params?.id as string,
      article: {
        ...model,
        tags: model.tags?.map((tag) => tag?.name),
      } as Orders.ArticlePayloadPost,
    });

    await router.push({ name: "articles", params: { id: route?.params?.id } });
  } catch (e) {
    await errorHandler?.handleApiExceptions(e);
  } finally {
    saving.value = false;
  }
}
</script>
