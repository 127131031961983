<template>
  <ModalCenter :show="show" class="top group" @after:leave="handleAfter">
    <div
      class="mx-auto w-screen max-w-[740px] transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
    >
      <Combobox v-model="selected">
        <div class="relative flex w-full items-center">
          <ComboboxInput
            class="block w-full rounded-md border-0 py-4 pl-4 pr-24 text-base font-normal text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-0"
            placeholder="Type to search (min. 4 characters)"
            :display-value="() => query"
            @change.stop="handleChange"
            @keyup.enter.prevent="onSelect"
          />
          <div class="absolute right-2 flex py-1.5 pr-1.5">
            <button
              v-if="query?.length > 0"
              type="button"
              class="mr-4"
              @click.prevent="handleResetSearch"
            >
              <XMarkIcon class="h-6 w-6 text-gray-900" />
            </button>

            <div v-if="searching" class="p-2">
              <loader-circle
                class="h-5 w-5 animate-spin fill-blue-600 text-gray-200"
              />
            </div>

            <button
              v-else
              type="button"
              @click.stop="handleSearch"
              :disabled="
                query?.length < MIN_CHARACTERS_TO_SEARCH ||
                searching ||
                (query !== '' && Object.entries(groups).length === 0)
              "
              class="btn-icon-primary-md"
            >
              <MagnifyingGlassIcon />
            </button>
          </div>
        </div>

        <ComboboxOptions
          v-if="Object.entries(groups).length > 0"
          static
          hold
          as="ul"
          class="mt-2 max-h-80 scroll-pb-2 scroll-pt-11 overflow-y-auto border-t-0 pb-2"
        >
          <li
            v-for="[group, items] in Object.entries(groups)"
            :key="group"
            class="first:mt-2"
          >
            <h2
              class="mt-0 px-4 py-0 text-sm font-semibold capitalize text-gray-900"
            >
              {{ getGroupName(group) }}
            </h2>
            <ul class="text-sm">
              <ComboboxOption
                v-for="(item, index) in items"
                :key="`${item.route_key}${index}`"
                :value="item"
                as="template"
                v-slot="{ active }"
                @click.prevent="onSelect"
              >
                <li
                  :class="[
                    'cursor-pointer select-none rounded-md border-b border-b-gray-100 px-4 py-2 last:border-b-0',
                    active &&
                      'bg-gray-50 font-semibold text-blue-600 outline-none',
                  ]"
                >
                  {{ item?.display_values?.reference }}
                </li>
              </ComboboxOption>
            </ul>
          </li>
        </ComboboxOptions>

        <div
          v-if="
            query !== '' &&
            query.length >= MIN_CHARACTERS_TO_SEARCH &&
            Object.entries(groups).length === 0 &&
            !searching
          "
          class="border-t border-gray-100 px-6 py-14 text-center text-sm sm:px-14"
        >
          <ComputerDesktopIcon
            class="mx-auto size-12 text-slate-400"
            aria-hidden="true"
          />
          <p class="mt-4 text-sm font-semibold text-slate-500">
            {{ t("common.components.sideAppMenu.no_results") }}
          </p>
          <p class="mt-2 text-sm font-normal text-slate-500">
            {{ t("common.components.sideAppMenu.no_results_body") }}
          </p>
          <p class="text-sm font-normal text-slate-500">
            {{ t("common.components.sideAppMenu.no_results_body_please") }}
          </p>
        </div>
      </Combobox>
    </div>
  </ModalCenter>
</template>

<script setup lang="ts">
import ModalCenter from "@/common/components/ModalCenter.vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import { ComputerDesktopIcon } from "@heroicons/vue/24/outline";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import { computed, inject, ref } from "vue";
import { EMPTY_STRING } from "@/common/helpers/constants";
import { reqGetSearch } from "@/common/helpers/api";
import groupBy from "lodash-es/groupBy";
import useErrorHandler from "@/common/composables/useErrorHandler";
import LoaderCircle from "@/common/components/LoaderCircle.vue";
import { type RouteLocation, useRouter } from "vue-router";
import { alertKey } from "@/common/plugins/alert";
import debounce from "lodash-es/debounce";

import { type SearchItem, SearchItemType } from "./index";
import { useI18n } from "vue-i18n";
import { passportKey } from "@/common/plugins/passport";

const passport = inject(passportKey, () => false);
const searchItems = ref<SearchItem[]>([]);
const searching = ref<boolean>(false);
const errorHandler = useErrorHandler();
const router = useRouter();
const toast = inject(alertKey);
const selected = ref<SearchItem | null>(null);
const query = ref<string>(EMPTY_STRING);
const { t } = useI18n();

const MIN_CHARACTERS_TO_SEARCH: Readonly<number> = 4;

defineProps<{ show: boolean }>();

const groups = computed(() => {
  let groupsByType = groupBy(searchItems.value, "type");
  if (!passport("list-orders")) {
    delete groupsByType?.[SearchItemType.ORDER];
  } else if (!passport("list-incidences")) {
    delete groupsByType?.[SearchItemType.INCIDENCE];
  } else if (!passport("list-linehauls")) {
    delete groupsByType?.[SearchItemType.LINEHAUL];
  } else if (!passport("list-dedicated_services")) {
    delete groupsByType?.[SearchItemType.DEDICATED_SERVICE];
  }
  return groupsByType;
});

async function handleSearch() {
  try {
    searchItems.value = [];
    searching.value = true;
    searchItems.value = await reqGetSearch(`?query=${query.value}`);
  } catch (exception) {
    await errorHandler.handleApiExceptions(exception);
  } finally {
    searching.value = false;
  }
}

function handleResetSearch() {
  query.value = EMPTY_STRING;
  searchItems.value = [];
  selected.value = null;
}

function getGroupName(group: string) {
  switch (group) {
    case SearchItemType.ORDER:
      return t("common.components.sideAppMenu.section_orders");
    case SearchItemType.INCIDENCE:
      return t("common.components.sideAppMenu.section_incidences");
    case SearchItemType.LINEHAUL:
      return t("common.components.sideAppMenu.section_linehauls");
    case SearchItemType.DEDICATED_SERVICE:
      return t("common.components.sideAppMenu.section_dedicated-services");
    default:
      toast?.error([`Item entity no found (${group})`]);
      break;
  }
}

function handleOpenItem(item: SearchItem) {
  let url = {} as RouteLocation & { href: string };
  switch (item?.type) {
    case SearchItemType.ORDER:
      url = router.resolve({
        name: "ordersDetail",
        params: { reference: item?.route_key },
      });
      break;
    case SearchItemType.INCIDENCE:
      url = router.resolve({
        name: "incidenceDetail",
        params: { id: item?.route_key },
      });
      break;
    case SearchItemType.LINEHAUL:
      url = router.resolve({
        name: "linehaulDetail",
        params: { id: item?.route_key },
      });
      break;
    case SearchItemType.DEDICATED_SERVICE:
      url = router.resolve({
        name: "dedicatedServicesDetail",
        params: { reference: item?.route_key },
      });
      break;
    default:
      toast?.error([`Item entity no found (${item?.type})`]);
      break;
  }
  if (url?.href) {
    window.open(url?.href, "_self");
  }
}

function onSelect() {
  if (selected.value) {
    handleOpenItem(selected.value);
  }
}

const handleChange = debounce(async ($event) => {
  query.value = $event.target.value;
  if (query.value.length < MIN_CHARACTERS_TO_SEARCH) {
    searchItems.value = [];
    selected.value = null;
    return;
  }
  await handleSearch();
}, 600);

function handleAfter() {
  query.value = EMPTY_STRING;
  searchItems.value = [];
}
</script>
