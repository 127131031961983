export default {
  title: "Addresses",
  base: "Base",
  ending: "Ending",
  edit_base_address: "Edit Base Address",
  edit_ending_address: "Edit Ending Address",
  customer_address: "Customer Address",
  additional: "Customer Address - Additional info",
  zip: "Zip Code",
  city: "City",
  province: "Province",
  country: "Country",
  no_ending_address: "No ending address",
  no_base_address: "No base address",
  placeholder_additional_address: "Insert additional info",
  placeholder_city: "Madrid",
  placeholder_zip: "28012",
  placeholder_province: "",
  placeholder_country: "",
};
